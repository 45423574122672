/* 飞天云动-解决方案-媒体运营 */
<template>
  <v-app>
    <v-banner single-line class="banner h5-carousel-new">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/LavMediaH5.png"
        height="100%"
        :transition="false"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <div class="h5-banner-info" style="margin-top: -10px;">
        <div class="width-per-100">
          <div class="font-size-h5banner-title ml-9">媒体运营</div>
        </div>
      </div>
    </v-banner>
    <v-container  style="" class="mb-10 mt-6">
      <v-card-title class="justify-center font-size-20 ">{{data.module.title}}</v-card-title>
      <div class="carousel-container">
        <swiper ref="mySwiper" :options="swiperOption">
            <swiper-slide v-for="(item,index) in data.module.itemList" :key="index">
              <div style="position: relative;border-radius:5px">
                <img :src="item.img" />
                <div class="coverleft" style="position: absolute;top:0">
                    <h3 class="font-size-14" style="font-weight: 400">{{item.sub}}</h3>
                </div>
              </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination" align="center" justify="center" style="width:100%;margin-top:22px"></div>
      </div>
    </v-container>
    <v-card color="#F5F8FC" flat class="wrap2 py-6 position-r">
      <v-card-title class="justify-center font-size-20  pa-0 line-height-28">{{data.module1.title}}</v-card-title>
      <v-tabs v-model="tab" background-color="transparent"
              class="xs-tab">
        <v-tab v-for="(v, k) in data.module1.itemList" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.module1.itemList.length - 1 ? 'mr-5':'']"
               style="min-width: unset" @click="currentIndex = 0">
          <b class="color-666 font-size-14 letter-spacing" style="font-weight: 400">{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in data.module1.itemList" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <div class="right-box">
              <v-card-text class="title text-align-center font-size-16" v-text="v.lunTitle" />
              <div class="box">
                <transition name="slide-fade" appear>
                  <div v-show="tab == k" >
                    <div 
                        v-for="(m,index) in v.btns"
                        :key="index"
                        class="right"
                        v-show="currentIndex==index"
                    >
                      <v-img
                        class="iphone-item d-flex align-center" height="100%"
                        :src="m.imgUrl"
                        contain
                      >
                        <!-- <img :class="m.id == 1? 'item-img-big' : ''"   :src="m.src1" alt="" v-show="m.id==1">
                        <img :class="m.id == 2? 'item-img-big2' : ''"  :src="m.src1" alt="" v-show="m.id==2">
                        <img :class="m.id == 3? 'item-img-big3' : ''"  :src="m.src1" alt="" v-show="m.id==3">
                        <img :class="m.id == 4? 'item-img-big4' : ''"  :src="m.src1" alt="" v-show="m.id==4"> -->
                      </v-img>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade-reverse" appear>
                  <div class="left" v-show="tab == k">
                    <v-card-text class="title" v-text="v.lunTitle1" />
                    <v-card-text class="text mt-4" v-text="v.tip" />
                    <div>
                      <div class="tab" v-cloak>
                        <div
                          v-for="(item,index) in v.btns"
                          :key="item.id"
                          :class='currentIndex==index?"active":""'
                          @click="change(index)">
                          {{item.button}}
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- <v-row>
                <v-col cols="6" v-for="(m,n) in v.btns" :key="n" class="img-item">
                  <v-img :src="m.imgUrl" width="100%" min-height="200" contain>
                    <template v-slot:placeholder><img-placeholder></img-placeholder></template>
                      <img :class="m.id == 1? 'item-img-big' : ''"   :src="m.src1" alt="" v-show="m.id==1">
                      <img :class="m.id == 2? 'item-img-big2' : ''"  :src="m.src1" alt="" v-show="m.id==2">
                      <img :class="m.id == 3? 'item-img-big3' : ''"  :src="m.src1" alt="" v-show="m.id==3">
                      <img :class="m.id == 4? 'item-img-big4' : ''"  :src="m.src1" alt="" v-show="m.id==4">
                  </v-img>
                </v-col>
              </v-row> -->
            </div>
            <!-- <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold line-height-25">{{v.lunTitle}}</v-card-title> -->
            <!-- <v-card-text class="pa-0 my-1 line-height-22"  v-text="v.tip" /> -->
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <v-card flat class="pb-6 h5model_list1">
      <v-card-title class="justify-center  font-size-20 color-333 pa-0 mt-6 line-height-28">{{data.module2.title}}</v-card-title>
      <div class="mt-4 px-3">
        <v-row v-for="(v,k) in data.module2.itemList" :key="k" class="justify-center d-flex flex-column align-center pt-5">
          <v-card-title class="pa-0 mt-1 font-size-16  line-height-20 justify-center">
            {{v.title}}
          </v-card-title>
          <div class="model_item mt-4">
            <img class="item_img" :src='v.src' />
            <div class="item_info">
              <v-card-title class="justify-center reset-title">{{ v.contentTitle }}</v-card-title>
              <v-card-text class="reset-text font-size-14">{{ v.content }}</v-card-text>
            </div>
          </div>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import lavData from '@/static/lavData.js'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "lavMedia",
  components: {
     Swiper,
     SwiperSlide,
  },
  data: () => ({
    data:lavData.lavMedia,
    tab: 0,
    currentIndex:0,
    swiperOption: {
      slidesPerView: "auto",
      centeredSlides:true,
      spaceBetween: -10,
      loop: true,
      autoplay: 3000,
      // pagination: {
      //  el: '.swiper-pagination',
      //  clickable:true
      // },
      pagination : '.swiper-pagination',
      paginationClickable :true,
      imgLoad:false,
      bannerLoad: false,
    },
  }),
  mounted(){
    this.bannerLoad = true;
  },
  watch:{
    tab(){
      this.currentIndex = 0;
    }
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    change(index){
      this.currentIndex = index;
    },
    handleTabBtn: function (type){
      const maxTab = this.data.module1.itemList.length - 1;
      this.currentIndex = 0
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* .item-img-big {
  width: 100px;
  height: 42px;
  position: absolute;
  top: 60px;
  right:28px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big2{
  width: 85px;
  height: 37px;
  position: absolute;
  top:173px;
  right:36px;
  animation-delay: 1s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big3{
  width: 112px;
  height: 160px;
  position: absolute;
  top: 57px;
  right:22px;
  border-radius: 10px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw1;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:5s;
}

.item-img-big4{
  width: 400px;
  height: 205px;
  position: absolute;
  top: 50px;
  right:-121px;
  animation-delay: 1s;
  z-index: -20;
  // animation-fill-mode:forwards;
  // 关键帧名称
  -webkit-animation-name:move;
  // 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
  // 动画播放的次数
  -webkit-animation-iteration-count:infinite;
  // // 动画所花费的时间
  -webkit-animation-duration:10s;
} */
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .v-card__text{
  line-height: 20px!important;
}
.carousel-container {
    .gitStyle{
      position: relative;
      .itemGif{
        position: absolute;
        top: 20px;
        left: 149px;
        z-index: 100;
        width: 101px;
        height: 65px;
        .imgone{
          border-radius:5px;
          width:170px;
          // height:155px
        }
        .imgTwo{
          //width: 167px;
          position: absolute;
          top: 14px;
          left: 34px;
          width:100%;
          height: 100%;
        }
      }
    }
    .coverleft{
      width: 176px;
      height: 100%;
      background: linear-gradient(180deg, rgba(41, 45, 49, 0.8) 0%, rgba(19, 15, 70, 0.7) 100%);
      border-radius: 5px 0px 0px 5px;
      padding-left:13px;
      padding-right: 10px;
      float: left;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      h3{
        color: #FFFFFF;
      }
    }
 .swiper-wrapper {
  .swiper-slide {
    //width: 85% !important;
    width: 90% !important;
    margin: 0 auto;
    //height: 280px !important;
    img {
      text-align: center;
      margin: 0 auto;
      width: 100%;
    }
  }
  .swiper-slide-prev{
    transform: scale(0.9);
  }
  .swiper-slide-next{
    transform: scale(0.9);
  }
}
}
::v-deep .swiper-pagination-bullet{
  margin-right: 10px;
}
.wrap2{
  .right-box{
    position: relative;
    .box {
      // width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .left {
      // text-align: left;
      margin-left:10px;
      .v-card__text {
        padding: 0;
      }
      .title {
        font-size: 18px !important;
        color: #333;
        line-height: 34px;
        // width: 280px;
        font-weight: 500;
      }
      .tip {
        margin: 12px 0 35px;
        font-size: 14px;
        color: #666666;
      }
      .text {
        font-size: 14px;
        // width: 366px;
        line-height: 26px;
      }
    }
    .right{
      width: 155px;
      height: 300px;
      position: relative;
    }
    .tab {
      // height: 120px;
      display: flex;
      align-items: center;
      flex-direction: column;
      div{
        margin: 18px 10px 0px 0px;
        box-sizing: border-box;
        float: left;
        list-style: none;
        cursor: pointer;
        text-align: center;
        width: 120px;
        height: 40px;
        background: #e0ebef;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 400;color: #666666;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
      .active{
        width: 120px;
        height: 40px;
        background: #3288FF;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.h5model_list1 {
  width: 100%;
  position: relative;
  .model_item{
    width: 300px;
    height: 210px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 15px 12px;
    text-align: center;
    // border: 1px solid red;
    .item_img{
      width: 260px;
      height: 146px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 10px;
    }
  }
}
</style>